import paper, {Path} from 'paper';
import SimplexNoise from 'simplex-noise';

export default function BlobCanvas(props) {
  // paper.install(window);

  const simplex = new SimplexNoise();
  let blobs = [];
  let mousePos = {x: 0, y: 0};

  window.onload = function () {
    paper.setup("paper-canvas");

    blobs = [0].map((_, i) => {
      const middleX = window.innerWidth/2;
      const middleY = window.innerHeight/2;
      const blob = new Path.Circle({
        center: [
          (Math.random()-0.5)*middleX + middleX,
          (Math.random()-0.5)*middleY + middleY
        ],
        radius: 400 + (Math.random()-0.5) * 400
      });

      blob.simplify();
      blob.fillColor = props.blobColor;
      blob.smooth({type: 'continuous'});

      return blob;
    });

    mousePos = paper.view.center / 2.0;
    paper.view.onMouseMove = (event) => {
      mousePos = event.point;
    }

    paper.view.onFrame = draw;
  }

   function draw(event) {
     const speed = 0.2;
     const magnitude = 2.0;

     blobs.forEach(blob => {
       blob.segments.forEach((segment, i)=> {
         const newX= simplex.noise2D(event.time*speed, i) * magnitude;
         const newY= simplex.noise2D(i, event.time*speed) * magnitude;

         segment.point.x += newX;
         segment.point.y -= newY;

         segment.point.x = segment.point.x > window.innerWidth ? window.innerWidth : segment.point.x;
         segment.point.x = segment.point.x < 0 ? 0 : segment.point.x;

         segment.point.y = segment.point.y > window.innerHeight ? window.innerHeight : segment.point.y;
         segment.point.y = segment.point.y < 0 ? 0 : segment.point.y;
       });
     });
   }

   // Most return null
   return null;
}
