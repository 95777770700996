import React from 'react';

import BlobCanvas from './BlobCanvas'

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.colorSchemes = [
      {
        background: "red",
        blobColor: [47/255, 57/255, 83/255, 255]
      },
      {
        background: "blue",
        blobColor: [237/255, 101/255, 87/255, 255]
      },
      {
        background: "tan",
        blobColor: [116/255, 182/255, 108/255, 255]
      },
      {
        background: "gunmetal",
        // blobColor: [15/255, 100/255, 120/255, 255]
        blobColor: [0,0,0,255]
      },
    ];

    this.colorScheme = this.chooseColorScheme();
    const fav = document.getElementById("favicon");
    document.querySelector("link[rel*='icon']").href = `${this.colorScheme.background}.ico`;

    this.state = {
      theme: null
    };
  }

  chooseColorScheme() {
    return this.colorSchemes[Math.floor(Math.random() * this.colorSchemes.length)];
  }

  toggleTheme() {
    this.setState({theme: this.state.theme ? null : "life-is-a-joke"})
  }

  render() {
    return (
      <div id="home" className={this.colorScheme.background + " " + this.state.theme}>
        {/* }<div id="goof-button" onClick={() => this.toggleTheme()}>Goof it/Spoof it</div> */}
        <div id="stuff">
            <h1 className="big-header">
            Star<br/>
            Player<br/>
            Sporting<br/>
            Goods
          </h1>
          <p className="detail">A record label for electronic music that <span className="emphasis">isn't boring</span></p>
        </div>

        <canvas id='paper-canvas' className='paper-boy' resize='true' />
        <BlobCanvas blobColor={this.colorScheme.blobColor} />

      </div>
    )
  }
}
